/*** GLOBAL ***/

body {
  font-weight: 300;
  font-size: 16px;
}

#speedcheckerdiv {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

.uk-button-primary {
  background-color: #0080FF;
}
.uk-radio:checked, .uk-checkbox:checked, .uk-checkbox:indeterminate {
  background-color: #0080FF;
}
.uk-radio:focus, .uk-checkbox:focus {
  border-color: #0080FF;
}
.uk-radio:disabled, .uk-checkbox:disabled {
  background-color: #f8f8f8;
  border-color: #e5e5e5;
}

a {
  transition: color .3s, border .3s;
  border-bottom: 1px solid transparent;
}
a:hover {
  text-decoration: none;
  border-bottom: 1px solid;
}

.do-account span, .do-account img {
  width: 220px;
}
.do-account a, .do-account a:hover {
  border: 0px hidden;
  display: inline-block;
  margin-left: -50px;
}
.do-account span {
  display: block;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: center;
}

.github-buttons {
  margin-top: 25px;
}

.github-button {
  margin: 0 4px;
  border-bottom: 0px hidden !important;
  display: inline-block;
  font-size: 0;
}

.github-link {
  position: absolute;
  transform: rotate(45deg);
  font-size: 0;
  z-index: 1;
  padding: 0;
  margin: 0;
  height: 70px;
  top: -10px;
  right: -45px;
}
.github-link i,
.github-link img {
  position: absolute;
  font-size: 40px;
  width: 36px;
  left: 70px;
  top: 35px;
  color: #0080FF;
  transform: translate(-50%, -50%);
}
.github-link::before {
  content: "";
  width: 0; 
  height: 0; 
  border-left: 70px solid transparent;
  border-right: 70px solid transparent;
  border-bottom: 70px solid white;
}

.version {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 10px;
  color: #0080FF;
  text-align: center;
}



/*** HEADER ***/

header {
  cursor: default;
  background: linear-gradient(180deg,#66a5ff,#0069ff);
  min-height: 230px;
  padding: 20px 0 30px;
  margin-bottom: 15px;
  color: white;
  overflow: hidden;
  position: relative;
}
header a {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  padding-bottom: 1px;
  font-weight: 600;
  border-bottom: 1px solid transparent;
}
header a:hover {
  color: #c1deff;
  text-decoration: none;
  border-bottom: 1px solid;
  border-color: #c1deff;
}

header h1 {
  font-size: 50px;
  line-height: 60px;
  font-weight: 300;
  letter-spacing: 5px;
  color: white;
}
header h3 {
  margin-top: 25px;
  font-size: 22px;
  line-height: unset;
  font-weight: 300;
  letter-spacing: 4px;
  color: white;
}
@media (max-width: 450px) {
  header h1 {
    padding: 25px 0 0;
  }
}

.hide-text {
  display: inline-block;
  text-indent: -9999px;
  white-space: nowrap;
}

.main-content {
  min-height: calc(100vh - 14px - 45px - 15px - 30px);
}

.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 30px 0 15px;
  padding: 0;
  text-align: center;
  list-style: none;
}

.footer li {
  font-size: 14px;
  line-height: 14px;
  border-left: 1px solid rgba(102, 165, 255, .5);
  padding: 0 .6em;
}
.footer li:first-child {
  border-left: none;
}

.badges {
  margin: 15px;
  text-align: center
}
.badges a {
  margin: 0 2px;
}
.badges a:hover {
  text-decoration: none;
  border: none;
}
.badges img {
  height: 16px;
}
.badges img.beer {
  height: 28px;
  vertical-align: sub;
}
